// extracted by mini-css-extract-plugin
export var spacer = "article-module--spacer--KAlBm";
export var wrap = "article-module--wrap--iPbCz";
export var wrapDark = "article-module--wrapDark--RllXG";
export var container = "article-module--container--DnXyP";
export var cardGrid = "article-module--cardGrid--8J1Ir";
export var normalTextBlock = "article-module--normalTextBlock--hQLIY";
export var paragraphs = "article-module--paragraphs--38DtH";
export var articleFooter = "article-module--articleFooter--3fy8K";
export var inlineSocialShareMobile = "article-module--inlineSocialShareMobile--tuTh+";
export var shareBlock = "article-module--shareBlock--KNLE+";
export var shareArrowIcon = "article-module--shareArrowIcon--7qlsm";
export var shareText = "article-module--shareText--JJLfj";
export var threeColumn = "article-module--threeColumn--6fM7z";
export var relatedReading = "article-module--relatedReading--UQ8Gx";
export var center = "article-module--center--xG8tF";
export var imageMaxWidth100 = "article-module--imageMaxWidth100--Yqbrg";
export var centerButtons = "article-module--centerButtons--zhUTC";
export var clearBg = "article-module--clearBg--i7yKv";
export var maxWidth1200 = "article-module--maxWidth1200--TAeP1";
export var logo = "article-module--logo--yCHbY";
export var slideUp = "article-module--slideUp--OCxkg";
export var slideUpLarge = "article-module--slideUpLarge--m0z3m";