// extracted by mini-css-extract-plugin
export var articleList = "articleList-module--articleList--ZAtf3";
export var offWhite = "articleList-module--offWhite--LTeJp";
export var white = "articleList-module--white--HBHXe";
export var articleListWrapper = "articleList-module--articleListWrapper--DDkfv";
export var header = "articleList-module--header--hKwZv";
export var articles = "articleList-module--articles--3exs8";
export var article = "articleList-module--article--nsgeh";
export var archive = "articleList-module--archive--zMDpp";
export var title = "articleList-module--title--VmPwS";
export var angle = "articleList-module--angle--NSVjS";
export var author = "articleList-module--author--XuIs8";
export var slideUp = "articleList-module--slideUp--NzD3d";
export var slideUpLarge = "articleList-module--slideUpLarge--Z7PKq";