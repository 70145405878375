import React, { Component, Suspense } from 'react';
import * as baseStyle from './articleList.module.scss';
import * as legacyStyle from './articleList.legacy.module.scss';
import LazyImage from '../ui/lazyImage';
import translations from '../../utils/translations';
import Loading from '../../components/global/loading';
import ArticleThemeContext from '../../context/articleThemeContext';

class ArticleList extends Component {
	static contextType = ArticleThemeContext;

	getArticleArchive = (article) => {
		return (article.archive) ? article.archive : null;
	}

	getArticleAuthors = (article) => {
		return (article.authors) ? article.authors.map((author) => author.name).join(' / ') : '';
	}

	render() {
		const style = this.context === 'dark' ? legacyStyle : baseStyle;
		let theme = '';
		if (this.context === 'light') {
			theme = this.props.theme === 'off-white' ? style.offWhite : style.white;
		}

		const {
			label,
			articles
		} = this.props;
		const isSSR = typeof window === "undefined";

		return (
			<>
      		{!isSSR && (
			<Suspense fallback={<Loading/>}>
				<div className={`${style.articleList} ${theme}`}>
					<div className={style.articleListWrapper}>
						<div className={style.header}>
							{label}
						</div>
						<div className={style.articles}>
							{articles.map((article, i) => {
								return (
									<div key={`articleList${i}`} className={style.article}>
										<a href={article.path}>
											<LazyImage
												src={article.image.sizes.near_square_md}
												alt={article.image.alt_text}
												hasRevealer={false}
												revealed={true}
												width={670}
												height={586}
												fitSize={true}
											/>
										</a>
										{this.getArticleArchive(article) &&
											<div className={style.archive}><a href={this.getArticleArchive(article).path}>{this.getArticleArchive(article).title}</a></div>
										}
										<div className={style.title}>
											<a href={article.path}>{article.title}&nbsp;<span className={style.angle}>&#8250;</span></a>
										</div>
										{this.getArticleAuthors(article) !== '' &&
											<div className={style.author}>{translations.t('by')} {this.getArticleAuthors(article)}</div>
										}
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</Suspense>
			)}
			</>
		);
	}
}

export default ArticleList;
