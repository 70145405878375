// extracted by mini-css-extract-plugin
export var articleList = "articleList-legacy-module--articleList--FM2ed";
export var articleListWrapper = "articleList-legacy-module--articleListWrapper--XSbF+";
export var header = "articleList-legacy-module--header--PuMuz";
export var articles = "articleList-legacy-module--articles--0PdaE";
export var article = "articleList-legacy-module--article--nm+j4";
export var archive = "articleList-legacy-module--archive--8tafy";
export var title = "articleList-legacy-module--title--Rb2XV";
export var angle = "articleList-legacy-module--angle--aF8I-";
export var author = "articleList-legacy-module--author--kROUE";
export var slideUp = "articleList-legacy-module--slideUp--wfW6y";
export var slideUpLarge = "articleList-legacy-module--slideUpLarge--oOmaW";