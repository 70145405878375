import { graphql } from 'gatsby';
import React, { Component } from 'react';
import HeadTags from '../components/global/headTags';
import basicMapDataToComponents from './basicMapDataToComponents';
import DefaultLayout from '../layouts/default-layout';
import ArticleList from '../components/article/articleList';
import translations from '../utils/translations';
import * as style from './article.module.scss';

class Basic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: 'en'
    };
  }

  render() {
    const latestArticleStories = this.props.data.megaMenu.edges.find(e => e.node.lang === this.state.lang).node.latest_article_stories;
    const isNewsletter = this.props.path === '/newsletter';

    const {
      title,
      paragraphs,
      meta_description,
      featured_image,
      basic_hero_background,
      hide_title,
      hide_side_nav,
      id
    } = this.props.data.allItemsJson.edges[0].node;

    return (
      <DefaultLayout {...this.props} excludeParentFunctions>
        <div
          className={`page-basic`}
          style={
            this.props.transition &&
            this.props.transition.style
          }
        >
          {
            basic_hero_background.image !== null &&
            <style>{`div.oneColMailChimpForm,div.twoColMailChimpForm,div.mailChimpThankYou{background-image:url(${basic_hero_background.image.sizes.hero_lg});}`}</style>
          }

          <HeadTags
            title={title}
            description={meta_description}
            image={featured_image ? featured_image.image.sizes.hero_md : null}
            imageTwitter={
              featured_image ? featured_image.image.sizes.hero_sm : null
            }
            location={this.props.location}
          />

          <div
            className="content-basic"
          >
            {
              !hide_title &&
              <div className={`typeFonts1 text-block page-title`}>
                <h1>{title}</h1>
              </div>
            }

            {basicMapDataToComponents(paragraphs, { reveal: true }, id)}
          </div>

          {
            hide_side_nav &&
            <style>{`nav.sideMenu,nav.sideMenu[style]{display:none!important;}`}</style>
          }

          {latestArticleStories && isNewsletter
            && <div className={style.relatedReading}>
              <ArticleList theme="off-white"
                articles={latestArticleStories}
                label={translations.t("Latest Stories")}
              />
            </div>
          }
        </div>
      </DefaultLayout>
    );
  }
}

export default Basic;

// eslint-disable-next-line
export const basicPageQuery = graphql`
  query BasicPageContentByPath(
    $path: String!,
    $isEs: Boolean!,
    $idRegex: String!,
    $blog: String!,
    $translations: String!
  ) {
    allItemsJson(filter: {path: {eq: $path}}) {
      edges {
        node {
	        id
          title
          meta_description
          related_challenge {
            title
          }
          featured_image {
            image {
              sizes {
                hero_lg
                hero_sm
                hero_md
                original
              }
            }
          }
          basic_hero_background {
            image {
              sizes {
                hero_lg
                hero_sm
                hero_md
                original
              }
            }
          }
          hide_title
          hide_side_nav
          ...queryParagraphFields
        }
      }
    }

    ...headerFields @skip(if:$isEs)
	...footerFields @skip(if:$isEs)
  ...headerFieldsEs @include(if:$isEs)
  ...footerFieldsEs @include(if:$isEs)
	challenges: allItemsJson(
		filter: {content_type: {regex: "/timeline|article|challenge/"}, jsonId: {regex: $idRegex}}
		) {
		edges {
			node {
			title
			content_type
			path
			related_challenge {
				title
				path
			}
			core_curriculum {
				title
				path
			}
			}
		}
		}
		pages: allItemsJson(
		filter: {content_type: {eq: "page"}, jsonId: {regex: $idRegex}}
		) {
		edges {
			node {
			title
			content_type
			path
			}
		}
		}
		blog: allItemsJson(filter: {jsonId: {eq: $blog}}) {
		edges {
			node {
			title
			content_type
			path
			}
		}
		}
		translations: allItemsJson(filter: {jsonId: {eq: $translations}}) {
		edges {
			node {
			translations {
				id
				key
				value
			}
			}
		}
	}
  megaMenu: allItemsJson(filter: {content_type: {eq: "mega_menu"}}) {
    edges {
      node {
      id
      jsonId
      content_type
      about {
        link
        title
      }
      our_team {
			  link
			  title
			}
      projects {
			  link
			  title
			}
      newsletter {
        link
        title
      }
      latest_stories {
        uri
        title
      }
      magazine {
        link
        title
      }
      educational_resources {
        uri
        title
        image_url
        image_alt
      }
      topics {
        display
        link
        langcode
      }
      latest_article_stories {
				id
				title
				path
				image {
					sizes {
						hero_lg
						hero_md
						hero_sm
						original
						near_square_md
					}
				}
				archive {
					id
					title
					path
				}
			}
      translated {
        stories_and_resources
        educational_resources
        latest_stories
        magazine
      }
      lang
      }
    }
    }
  }
`;
